<template>
  <div>
    <div class="w-auto" v-if="loadingAllProjet">
      <clip-loader :loading="loadingAllProjet" color="primary"></clip-loader>
    </div>
    <div v-else>
      <vx-card
        :title="`Emissions contrats (${allProjects.list.length})`"
        class="mb-4"
      >
        <div slot="no-body">
          <div class="p-8">
            <div class="pt-2 pb-4">
              <div class="pt-2 pb-2">
                <vs-collapse>
                  <vs-collapse-item class="aps-benin-v2-collapse-item">
                    <div slot="header">
                      <div class="aps-benin-v2-collapse-filter">Filtrage</div>
                    </div>
                    <div class="p-3">
                      <vs-row vs-w="12" vs-align="center">
                        <vs-col
                          class="pr-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <span class="pl-1 pr-1">Du</span>
                          <div
                            class="inputx w-full flex justify-around"
                            style="align-items: center"
                          >
                            <vs-input
                              v-model="startDate"
                              type="date"
                              class="
                                aps-benin-v2-disable-input-date
                                inputx
                                w-full
                                mb-2
                                mt-2
                              "
                            />
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <span class="pl-1 pr-1">au </span>
                          <div
                            class="inputx w-full flex justify-around"
                            style="align-items: center"
                          >
                            <vs-input
                              type="date"
                              v-model="endDate"
                              :min="startDate"
                              class="
                                aps-benin-v2-disable-input-date
                                inputx
                                w-full
                                mb-2
                                mt-2
                              "
                            />
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mb-2 mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <span class="pl-2">Intermédiaire</span>
                            <multiselect
                              v-model="intermediary"
                              :options="intermediariesList"
                              :close-on-select="true"
                              placeholder="--Choisir un intermédiaire--"
                              label="fullname"
                              track-by="fullname"
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            ></multiselect>
                          </div>
                        </vs-col>

                        <vs-col
                          v-if="crrModuleName != 'AHR'"
                          class="pr-4 mb-2 mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <span class="pl-2">Avis de souscription</span>
                            <multiselect
                              v-model="subscription"
                              :options="subscriptionNoticeList"
                              :close-on-select="true"
                              placeholder="--Choisir un avis de souscription--"
                              label="label"
                              track-by="label"
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            ></multiselect>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4 mb-2 mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <span class="pl-2">Canal</span>
                            <multiselect
                              v-model="canal"
                              :options="allCanal"
                              :close-on-select="true"
                              placeholder="--Choisir un canal--"
                              label="label"
                              track-by="label"
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            ></multiselect>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4 mb-2 mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <span class="pl-2">Statut paiement</span>
                            <multiselect
                              v-model="paymentStatus"
                              :options="paymentStatusList"
                              :close-on-select="true"
                              placeholder="--Choisir un statut paiement--"
                              label="label"
                              track-by="label"
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            ></multiselect>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4 mb-2 mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <span class="pl-2"
                              >Catégorie socio-professionenelle</span
                            >
                            <multiselect
                              v-model="categorySocPro"
                              :options="categorySocProList"
                              :close-on-select="true"
                              placeholder="--Choisir une catégorie socio-professionenelle--"
                              label="label"
                              track-by="label"
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            ></multiselect>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4 mb-2 mt-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <span class="pl-2">Situation familiale</span>
                            <multiselect
                              v-model="famillySituation"
                              :options="familySituationList"
                              :close-on-select="true"
                              placeholder="--Choisir une situation familiale--"
                              label="label"
                              track-by="label"
                              :selectLabel="global.selectLabel"
                              :selectedLabel="global.selectedLabel"
                              :deselectLabel="global.deselectLabel"
                            ></multiselect>
                          </div>
                        </vs-col>
                      </vs-row>
                      <vs-row vs-w="12" vs-align="center" vs-justify="center">
                        <vs-col
                          class="pt-4 pb-2"
                          vs-type="flex"
                          vs-align="center"
                          vs-justify="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <vs-button @click="resetFilter"
                            >Réinitialiser</vs-button
                          >
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-collapse-item>
                </vs-collapse>
              </div>
            </div>
            <div>
              <vs-prompt
                title="Exportation"
                class="export-options"
                @cancel="clearFields"
                @accept="exportToDataSubscription"
                accept-text="Valider"
                cancel-text="Annuler"
                @close="clearFields"
                :active.sync="activePrompt"
              >
                <vs-input
                  v-model="fileName"
                  placeholder="Entrez le nom du fichier..."
                  class="w-full"
                />
                <multiselect
                  v-model="selectedFormat"
                  :options="formats"
                  class="my-4"
                  placeholder="--Choisir--"
                  :selectLabel="global.selectLabel"
                  :selectedLabel="global.selectedLabel"
                  :deselectLabel="global.deselectLabel"
                >
                  <template v-slot:noOptions>
                    <span>{{ global.selectNotFoundData }}</span>
                  </template>
                </multiselect>
                <div class="flex">
                  <span class="mr-4">Largeur automatique de cellule:</span>
                  <vs-switch v-model="cellAutoWidth"
                    >Largeur automatique de cellule</vs-switch
                  >
                </div>
              </vs-prompt>
              <vs-prompt
                title="Exportation"
                class="export-options"
                @cancel="clearFields"
                @accept="exportToDataMailing"
                accept-text="Valider"
                cancel-text="Annuler"
                @close="clearFields"
                :active.sync="activePromptMailing"
              >
                <vs-input
                  v-model="fileName"
                  placeholder="Entrez le nom du fichier..."
                  class="w-full"
                />
                <multiselect
                  v-model="selectedFormat"
                  :options="formats"
                  class="my-4"
                  placeholder="--Choisir--"
                  :selectLabel="global.selectLabel"
                  :selectedLabel="global.selectedLabel"
                  :deselectLabel="global.deselectLabel"
                >
                  <template v-slot:noOptions>
                    <span>{{ global.selectNotFoundData }}</span>
                  </template>
                </multiselect>
                <div class="flex">
                  <span class="mr-4">Largeur automatique de cellule:</span>
                  <vs-switch v-model="cellAutoWidth"
                    >Largeur automatique de cellule</vs-switch
                  >
                </div>
              </vs-prompt>

              <div
                class="
                  flex flex-wrap-reverse
                  items-center
                  data-list-btn-container
                "
              >
                <div>
                  <div>
                    <vs-dropdown class="dd-actions cursor-pointer">
                      <div
                        class="
                          p-3
                          shadow-drop
                          rounded-lg
                          d-theme-dark-bg
                          cursor-pointer
                          flex
                          items-center
                          justify-center
                          text-lg
                          font-medium
                          w-32 w-full
                        "
                      >
                        <span class="mr-2">Afficher {{ limit }}</span>
                        <feather-icon
                          icon="ChevronDownIcon"
                          svgClasses="h-4 w-4"
                        />
                      </div>
                      <vs-dropdown-menu>
                        <vs-dropdown-item
                          :key="i"
                          v-for="(r, i) in rowsTable"
                          @click="limit = r"
                        >
                          <span class="flex items-center">
                            <span>{{ r }}</span>
                          </span>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </div>
                <div>
                  <vs-dropdown class="dd-actions cursor-pointer ml-4">
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Actions</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="activePromptMailing = true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="PrinterIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Exporter Mailing</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="fetchApplyGetAllProjectDepth">
                        <span class="flex items-center">
                          <feather-icon
                            icon="PrinterIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Exporter Souscriptions</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        v-if="checkUserRole('contract_issue').delete"
                        :disabled="contratSelected.length == 0"
                        @click="checkRemoveMultipleContrat"
                      >
                        <span class="flex items-center">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Supprimer la sélection</span>
                        </span>
                      </vs-dropdown-item>
                      <vx-tooltip
                        color="primary"
                        :title="role.notAllowTitle"
                        :text="role.notAllowText"
                        v-else
                      >
                        <vs-dropdown-item :disabled="true">
                          <span class="flex items-center">
                            <feather-icon
                              icon="Trash2Icon"
                              svgClasses="h-4 w-4"
                              class="mr-2"
                            />
                            <span>Supprimer la sélection</span>
                          </span>
                        </vs-dropdown-item>
                      </vx-tooltip>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div class>
                <div class="w-auto" v-if="loadingForTable">
                  <clip-loader
                    :loading="loadingForTable"
                    color="primary"
                  ></clip-loader>
                </div>
                <div v-else>
                  <div v-if="allProjects.list.length !== 0">
                    <vs-table
                      multiple
                      :data="allProjects.list"
                      v-model="contratSelected"
                      search
                      stripe
                      pagination
                      :max-items="limit != 'Tout' ? limit : total"
                      noDataText="Aucun résultat trouvé"
                    >
                      <template slot="thead">
                        <vs-th>N°</vs-th>
                        <vs-th sort-key="broker">Intermédiaire</vs-th>
                        <vs-th sort-key="identity">Identité</vs-th>
                        <vs-th sort-key="dateSouscription"
                          >Date souscription</vs-th
                        >
                        <vs-th sort-key="type">Canal</vs-th>
                        <vs-th v-if="crrModuleName != 'AHR'" sort-key="notice"
                          >Avis de souscription</vs-th
                        >
                        <vs-th sort-key="paymentStatus"
                          >Statut de paiement</vs-th
                        >
                        <vs-th>Actions</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td :data="data[indextr].id">
                            {{ indextr + 1 }}
                          </vs-td>
                          <vs-td :data="data[indextr].broker">
                            {{
                              data[indextr].broker
                                ? `${data[indextr].broker.person.firstname} ${data[indextr].broker.person.lastname}`
                                : ""
                            }}
                          </vs-td>
                          <vs-td :data="data[indextr].subscription.subscriber">
                            <div v-if="data[indextr].subscription"> 
                              <div v-if="data[indextr].subscription.subscriber.byType == 'individual'" >
                                {{ data[indextr].subscription.subscriber.firstname }}
                                {{ data[indextr].subscription.subscriber.lastname }}
                              </div>
                              <div v-if="data[indextr].subscription.subscriber.byType == 'company'" >
                                {{ data[indextr].subscription.subscriber.companyName }}
                                ({{ data[indextr].subscription.subscriber.lastname }} {{ data[indextr].subscription.subscriber.firstname }})
                              </div>
                            </div>
                          </vs-td>

                          <vs-td :data="data[indextr].dateSouscription">
                            {{ ` ${dateElement(data[indextr]).createdAtInfo}` }}
                          </vs-td>
                          <vs-td :data="data[indextr].type">{{
                            displayType(data[indextr].type)
                          }}</vs-td>
                          <vs-td
                            v-if="crrModuleName != 'AHR'"
                            :data="data[indextr].notice"
                          >
                            {{ displayNotice(data[indextr].notice) }}
                          </vs-td>

                          <vs-td :data="data[indextr].id">
                            <div
                              v-if="
                                crrModuleName == 'AHR' || crrModuleName == 'AAE'
                              "
                            >
                              {{
                                paymentStatusList.find(
                                  (el) =>
                                    el.id ==
                                    data[indextr]
                                      .getDefaultQuittancePayementStatus
                                )
                                  ? paymentStatusList.find(
                                      (el) =>
                                        el.id ==
                                        data[indextr]
                                          .getDefaultQuittancePayementStatus
                                    ).label
                                  : ""
                              }}
                            </div>
                            <div v-else>
                              {{
                                paymentStatusList.find(
                                  (el) =>
                                    el.id ==
                                    data[indextr]
                                      .getDefaultAvenantPayementStatus
                                )
                                  ? paymentStatusList.find(
                                      (el) =>
                                        el.id ==
                                        data[indextr]
                                          .getDefaultAvenantPayementStatus
                                    ).label
                                  : ""
                              }}
                            </div> 
                          </vs-td>
                          <vs-td :data="data[indextr].id">
                            <div class="inline-flex" @click.stop>
                              <vs-dropdown  class="dd-actions cursor-pointer">
                                <div class="cursor-pointer">
                                  <feather-icon
                                    icon="AlignJustifyIcon"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                                <vs-dropdown-menu class="aps-benin-v2-dropdown">
                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue')
                                          .update &&
                                        (data[indextr].productName == 'SOHU') &&
                                        data[indextr]
                                          .getDefaultAvenantPayementStatus ==
                                          'required'
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkPayed(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="CreditCardIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Payer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vs-dropdown-item
                                      v-else-if="
                                        checkUserRole('contract_issue')
                                          .update &&
                                        (data[indextr].productName == 'AHR' ||
                                          data[indextr].productName == 'AAE') &&
                                        data[indextr]
                                          .getDefaultQuittancePayementStatus ==
                                          'required'
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkPayed(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="CreditCardIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Payer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="CreditCardIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Payer</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue')
                                          .cancel &&
                                        (data[indextr]
                                          .getDefaultAvenantPayementStatus ==
                                          'payOff' ||
                                          data[indextr]
                                            .getDefaultQuittancePayementStatus ==
                                            'payOff')
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkCancelPayed(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="DeleteIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Annuler Paiement</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue')
                                          .update &&
                                        (data[indextr].productName == 'SOHU') &&
                                        data[indextr]
                                          .getDefaultAvenantPayementStatus ==
                                          'payOff'
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="enabledPolice(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="UnlockIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Activer Police</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vs-dropdown-item
                                      v-else-if="
                                        checkUserRole('contract_issue')
                                          .update &&
                                        (data[indextr].productName == 'AAE' ||
                                          data[indextr].productName == 'AHR') &&
                                        data[indextr]
                                          .getDefaultQuittancePayementStatus ==
                                          'payOff'
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="enabledPolice(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="UnlockIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Activer Police</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="UnlockIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3"
                                              >Activer Police</span
                                            >
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>
                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue').show
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="getFilesList(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="DownloadIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Télécharger</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="DownloadIcon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3"
                                              >Télécharger</span
                                            >
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>
                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue').show
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkComment(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="FeatherIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">
                                            {{
                                              `Commenter (${data[indextr].countComment})`
                                            }}
                                          </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="FeatherIcon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">{{
                                              `Commenter (${data[indextr].commentsProject.length})`
                                            }}</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>
                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue').update
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkRelaunch(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="RefreshCcwIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Relancer</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="RefreshCcwIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Relancer</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue').change
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="
                                          ChangeIntermediary(data[indextr])
                                        "
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="RepeatIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Changer intermédiaire</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="RepeatIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3"
                                              >Changer intermédiaire</span
                                            >
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      color="primary"
                                      v-if="
                                        checkUserRole('contract_issue').delete 
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="classerSansSuite(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            class="text-primary"
                                            icon="LockIcon"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"
                                            >Classer sans suite</span
                                          >
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              class="text-primary"
                                              icon="LockIcon"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3"
                                              >Classer sans suite</span
                                            >
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>

                                  <div>
                                    <vs-dropdown-item
                                      v-if="
                                        checkUserRole('contract_issue').delete
                                      "
                                    >
                                      <div
                                        class="ml-1 cursor-pointer"
                                        @click="checkRemove(data[indextr])"
                                      >
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="Trash2Icon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Supprimer</span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                    <vx-tooltip
                                      v-else
                                      color="primary"
                                      :title="role.notAllowTitle"
                                      :text="role.notAllowText"
                                    >
                                      <vs-dropdown-item disabled>
                                        <div class="ml-1 cursor-pointer">
                                          <span class="flex items-center">
                                            <feather-icon
                                              icon="Trash2Icon"
                                              class="text-primary"
                                              svgClasses="w-6 h-6"
                                            ></feather-icon>
                                            <span class="m-3">Supprimer</span>
                                          </span>
                                        </div>
                                      </vs-dropdown-item>
                                    </vx-tooltip>
                                  </div>
                                </vs-dropdown-menu>
                              </vs-dropdown>
                              <div
                                class="ml-2"
                                v-show="woIsAction == data[indextr].id"
                              >
                                <clip-loader
                                  :loading="
                                    loadingRemoveContrat || loadingUpdateContrat
                                  "
                                  color="primary"
                                  size="25px"
                                ></clip-loader>
                              </div>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <vs-pagination
                      class="mt-6"
                      :total="allPages"
                      v-model="currentPage"
                    ></vs-pagination>
                  </div>
                  <div v-else>
                    <div class="flex items-center justify-center mt-8">
                      <strong>Aucun enregistrement</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <vs-popup
              class="holamundo"
              title="Comment souhaitez-vous payer la cotisation ?"
              :active.sync="popupPaiement"
            >
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      id="pay-btn"
                      :class="`current_project_${crrID}`"
                      class="w-full sm:w-auto"
                      @click="checkPayedWithAgregator"
                      >Payer en ligne</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="payedWithoutAgregator"
                      >Marquer paiement</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupPaiement = false"
                      >Annuler</vs-button
                    >
                  </div>
                </div>
              </div>
            </vs-popup>
            <vs-popup
              class="holamundo"
              title="Souhaitez-vous modifier la cotisation ?"
              :active.sync="popupPaiementUpdateTTC"
            >
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <div>
                      <vs-input
                        type="number"
                        class="aps-benin-v2-form-number inputx w-full mb-2 mt-2"
                        label="Cotisation TTC*"
                        v-model="formPayed.cotisationTTC"
                        v-validate="'required|numeric'"
                        data-vv-validate-on="blur"
                        name="Montant"
                      />
                      <span class="text-danger text-sm">{{
                        errors.first("Montant")
                      }}</span>
                    </div>
                    <div class="mt-8">
                      <div class="inline-flex">
                        <div>
                          <vs-button
                            class="w-full sm:w-auto"
                            :disabled="!validateFormPayed || loadingFormPayed"
                            @click="fetchApplycheckPayedWithAgregator"
                            >Payer</vs-button
                          >
                          <vs-button
                            class="w-full sm:w-auto ml-4"
                            type="border"
                            @click="popupPaiementUpdateTTC = false"
                            >Annuler</vs-button
                          >
                        </div>
                        <div class="ml-2">
                          <clip-loader
                            :loading="loadingFormPayed"
                            color="primary"
                          ></clip-loader>
                        </div>
                      </div>
                      <div class="mt-6">
                        <p class="text-danger">* Champ obligatoire</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-popup>
            <vs-popup
              class="holamundo"
              title="Paiement"
              :active.sync="popupMarkPayment"
            >
              <div class="m-4">
                <aps-benin-v2-subscribe-mark-payment
                  v-on:onDisplayMarkPayment="onDisplayMarkPayment"
                  crrModuleName="ContractIssue"
                  :item="formUpdated"
                  :byProject="formUpdated"
                  :isClo="popupMarkPayment"
                ></aps-benin-v2-subscribe-mark-payment>
              </div>
            </vs-popup>
            <vs-popup
              class="holamundo"
              title="Relancer"
              :active.sync="popupRelaunch"
            >
              <div class="m-4">
                <aps-benin-v2-subscribe-relaunch
                  v-on:onDisplayRelaunch="onDisplayRelaunch"
                  :crrModuleName="crrModuleName"
                  :item="formUpdated"
                ></aps-benin-v2-subscribe-relaunch>
              </div>
            </vs-popup>
            <vs-popup
              class="holamundo"
              title="Activer Police"
              fullscreen
              :active.sync="popupEnabledPolice"
            >
              <div class="m-4">
                <aps-benin-v2-subscribe-enabled-police
                  v-on:onDisplayEnabledPolice="onDisplayEnabledPolice"
                  crrModuleName="ContractIssue"
                  :item="formUpdated"
                  :byProject="formUpdated"
                  :isClo="popupEnabledPolice"
                ></aps-benin-v2-subscribe-enabled-police>
              </div>
            </vs-popup>

            <!-- Commenter -->
            <aps-benin-v2-subscribe-comment
              v-on:onListen="isClosed"
              :crrModuleName="crrModuleName"
              :item="souscriptionId"
              :isClo="isClose"
            >
            </aps-benin-v2-subscribe-comment>

            <vs-popup
              class="holamundo"
              title="Changer un intermédiaire"
              :active.sync="popupActiveChangeIntermediary"
            >
              <div class="m-4">
                <div class="clearfix">
                  <div>
                    <vs-row vs-w="12">
                      <vs-col
                        class="mb-6"
                        vs-type="flex"
                        vs-justify="right"
                        vs-align="right"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="flex w-full" v-if="formInterm.broker">
                          <div class="flex">
                            <div>
                              <strong>Intermédiaire actuel : </strong>
                              {{ formInterm.broker.person.firstname }}
                              {{ formInterm.broker.person.lastname }}
                            </div>
                          </div>
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Changer un intermédiaire*</span>
                          <multiselect
                            v-model="intermediaryUpdated"
                            :options="intermediariesList"
                            :close-on-select="true"
                            placeholder="--Choisir un intermédiaire--"
                            label="fullname"
                            track-by="fullname"
                            v-validate="'required'"
                            name="Intermediaire"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                            <template v-slot:noOptions>
                              <span>{{ global.selectNotFoundData }}</span>
                            </template>
                          </multiselect>

                          <div class="aps-benin-v2-error-msg">
                            {{ errors.first("Intermediaire") }}
                          </div>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                <div class="mt-8">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        class="w-full sm:w-auto"
                        @click="fetchApplyChangeIntermediary"
                        >Changer</vs-button
                      >
                      <vs-button
                        class="w-full sm:w-auto ml-4"
                        type="border"
                        @click="popupActiveChangeIntermediary = false"
                        >Annuler</vs-button
                      >
                    </div>
                    <div class="ml-2">
                      <clip-loader
                        :loading="loadingChangeIntermediary"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                  <div class="mt-6">
                    <p class="text-danger">* Champ obligatoire</p>
                  </div>
                </div>
              </div>
            </vs-popup>
          </div>
        </div>
      </vx-card>

      <vs-popup
        class="holamundo"
        title="Liste des fichiers"
        :active.sync="popupListFiles"
      >
        <div v-if="filesList.length != 0" class="m-4">
          <p
            v-for="item in filesList"
            :key="item.filename"
            style="cursor: pointer"
            :style="item.valideQMStandard ? 'color: green;' : ''"
            @click="checkDropFile(item.filename)"
          >
            <b><span> {{ item.name }} </span></b>
            <img style="height: 40px;" v-if="item.valideQMStandard" src="@/assets/images/pages/like.png" alt="like" />
            <vs-divider />
          </p>
        </div>
        <div v-else>
          <div class="flex items-center justify-center mt-8">
            <strong>Aucun enregistrement</strong>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import role from "@/config/role";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "aps-benin-v2-subscribe-contract-issue",
  props: {
    crrModuleName: String,
    subscriptionId: Number,
    contratCounter: Number,
  },
  components: {
    ClipLoader,
    Multiselect,
  },
  data() {
    return {
      crrID: "",
      intermediariesList: [],
      allStatus: [],
      subscription: "",
      endDate: "",
      startDate: "",
      allCanal: [],
      canal: "",
      categorySocPro: "",
      categorySocProList: [],
      famillySituation: "",
      familySituationList: [],
      paymentStatus: "",
      subscriptionNoticeList: [],
      paymentStatusList: [],
      intermediary: "",
      intermediaryUpdated: "",
      loading: false,
      loadingForTable: false,
      isClose: false,
      loadingAllProjet: false,
      loadingRemoveContrat: false,
      loadingChangeIntermediary: false,
      loadingUpdateContrat: false,
      popupActiveChangeIntermediary: false,
      role: {},
      byProductContent: {},
      loadingFormPayed: false,
      formPayed: {
        cotisationTTC: 0,
      },
      global: {},
      formInterm: {},
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      souscriptionId: 0,
      //Pagination
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "status"],
      activePrompt: false,
      activePromptMailing: false,
      //end export section
      contratSelected: [],
      crrIDs: [],
      woIsAction: "",
      popupPaiement: false,
      popupPaiementUpdateTTC: false,
      popupAgregateur: false,
      popupMarkPayment: false,
      popupEnabledPolice: false,
      popupRelaunch: false,
      formUpdated: {},
      popupListFiles: false,
      filesList: [],
    };
  },
  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("suscribe", ["allProjects", "allProjectsDepth"]),
    ...mapGetters("general", [
      "rowsTable",
      "allProduct",
      "allCategorySocioPro",
      "familySituation",
      "canalList",
      "noticeList",
      "stateList",
      "subscriptionNotice",
      "allPaymentStatus",
      "allPeriodicityOfPayment",
      "allContratStatus",
    ]),
    validateFormPayed() {
      return !this.errors.any() && this.formPayed.cotisationTTC != "";
    },
  },
  mounted() {
    if (!this.checkUserRole("contract_issue").all)
      this.$router.push({ name: "Home" });
    this.limit = this.rowsTable[1];
    this.getIntermediarySys();
    this.allStatus = this.stateList;
    this.allCanal = this.canalList;
    this.categorySocProList = this.allCategorySocioPro;
    this.familySituationList = this.familySituation;
    this.subscriptionNoticeList = this.subscriptionNotice.filter(
      (notice) => notice.id != "rejection"
    );
    this.paymentStatusList = this.allPaymentStatus;

    addKkiapayCloseListener(() => {
      this.woIsAction = "";
      this.loadingUpdateContrat = false;
    });

    // Check Event Payment
    if (localStorage.getItem("byTypePayment") == "ContractIssue") {
      if (!window.callbackPaymentContratIssueregistred) {
        window.callbackPaymentContratIssueregistred = true;
        addSuccessListener((response) => {
          console.log("response", response);
          if (response.transactionId) {
            this.woIsAction = "";
            this.loadingUpdateContrat = false;
            localStorage.removeItem("byTypePayment");

            this.acceptAlert({
              color: "success",
              text: "Paiement en cours de validation",
              title: "Paiement",
            });
          } else {
            this.acceptAlert({
              color: "danger",
              text: "Erreur lors du paiement",
              title: "Paiement",
            });
          }
          window.callbackPaymentContratIssueregistred = false;
        });
      }
    }
  },

  methods: {
    getDefaultAvenantPayementStatus(projectAvenants) {
      return mixin.methods.getDefaultAvenantPayementStatus(projectAvenants);
    },
    getDefaultQuittancePayementStatus(projectQuittances) {
      return mixin.methods.getDefaultQuittancePayementStatus(projectQuittances);
    },
    ...mapActions("auth", ["logout"]),
    ...mapActions("suscribe", [
      "applyGetAllProject",
      "applyChangeProjectState",
      "applyRemoveProject",
      "applyChangeIntermediary",
      "applyChangeAvenantState",
      "applyChangeQuittanceState",
      "applyPutAvenant",
      "applyPutAllQuittances",
      "applyPutQuittance",
      "applyGetAllProjectDepth",
    ]),
    ...mapActions("users", ["applyGetIntermediariesSys"]),
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    classerSansSuite(item) {
      this.crrID = item.id;
      this.formUpdated = { ...item };

      this.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: `Confirmation`,
      text: `Voulez-vous vraiment fermer ce projet en cours ?`,
      acceptText: "Confirmer",
      cancelText: "Annuler",
      accept: this.closeProject,
      });
    },
    async closeProject() {
      this.loadingUpdateProject = true;
      this.woIsAction = this.crrID;

      let data = {
        ...this.formUpdated,
        projectId: this.crrID,
        status: "projetNotOk",
      };
      console.log("stat:", data.status);
      try {
        await this.applyChangeProjectState(data);
        this.acceptAlert(
          mixin.methods.message("ongoing_project", "close").success
        );
        this.formUpdated = {};
        this.woIsAction = "";
        this.loadingUpdateProject = false;
        window.location.reload()

      } catch (err) {
        this.woIsAction = "";
        this.loadingUpdateProject = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("ongoing_project", "close").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    exportToDataMailing() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allOngoingProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];

        this.allProjects.list.forEach((el) => {
          let jsonTmp = {
            ...this.onGetSubscriber(el.subscription.subscriber),
            broker_firstname: el.broker.person.firstname,
            broker_lastname: el.broker.person.lastname,
            broker_society: el.broker.person.forSociety,
            broker_email: el.broker.email,
            broker_phone: el.broker.person.phone,
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "subscriber_sexe",
          "subscriber_firstname",
          "subscriber_lastname",
          "subscriber_email",
          "subscriber_phone",

          "broker_firstname",
          "broker_lastname",
          "broker_society",
          "broker_email",
          "broker_phone",
        ];

        let headerTitleTmp = [
          "Civilité",
          "Prénoms",
          "Nom",
          "E-mail",
          "Téléphone ",
          "Prénom intermédiaire",
          "Nom intermédiaire",
          "Société intermédiaire",
          "E-mail intermédiaire",
          "Téléphone intermédiaire",
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }

      this.clearFields();
    },
    exportToDataSubscription() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            identity: el.identity,
            dateSouscription: el.dateSouscription,
            status: el.status,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES SOUSCRIPTIONS"
        );
      } else {
        console.log(this.allProjectsDepth.list, "subscription list");
        let tableToPrint = [];
        this.allProjectsDepth.list.forEach((el) => {
          let jsonTmp = {
            prospect_sexe: el.quote.civility,
            prospect_lastname: el.quote.lastname,
            prospect_firstname: el.quote.firstname,
            prospect_birthday: this.formatDate(el.quote.birthday),
            prospect_phone: el.quote.phone,
            prospect_startDateContributions: this.formatDate(
              el.quote.startDateContributions
            ),
            prospect_email: el.quote.email,

            // profil_broker: this.profilList.find((type) => type.id == el.broker.type)
            //   ? this.profilList.find((type) => type.id == el.broker.type).label
            //   : "",
            // type_broker: this.brokerTypeList.find(
            //   (type) => type.id == el.personality
            // )
            //   ? this.brokerTypeList.find((type) => type.id == el.personality)
            //       .label
            //   : "",
            // userCode_broker: el.broker.userCode,
            // lastname_broker: el.broker.person.lastname,
            // firstname_broker: el.broker.person.firstname,
            // forSociety_broker: el.broker.person.forSociety,
            // addres_broker: el.broker.person.addres,
            // phone_broker: el.broker.person.phone,
            username_broker: `${el.broker.person.firstname} ${el.broker.person.lastname}`,
            // email_broker: el.broker.email,
            // logo_broker: this.showFileData(el.person.logo),

            // assure
            ...this.onGetAssured(el.subscription.assured),
            // subscriber
            ...this.onGetSubscriber(el.subscription.subscriber),
            // beneficiariesDeceased
            ...this.onGetBeneficiariesDeceased(
              el.subscription.beneficiariesDeceased
            ),
            // survey
            ...this.onGetSurvey(el.subscription.survey),
            ...this.onGetTarif(el.productName, el.quote),
            ref: el.ref,
            status: this.displayNotice(el.notice),
            contrat_status: "Inactif",
            ref_payement: this.onGetPaymentStatus(el),
            // clientNumber: el.client.username
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [];
        let headerTitleTmp = [];
        switch (this.crrModuleName) {
          case "SOHU":
            headerValTmp = [
              "status",
              "contrat_status",
              "ref",
              "ref_payement",
              // "profil_broker",
              // "type_broker",
              // "firstname_broker",
              // "lastname_broker",
              "username_broker",
              // "userCode_broker",
              // "email_broker",
              // "forSociety_broker",
              // "phone_broker",
              // "addres_broker",
              // "logo_broker",

              "prospect_sexe",
              "prospect_lastname",
              "prospect_firstname",
              "prospect_birthday",
              "prospect_phone",
              "prospect_email",
              "prospect_startDateContributions",

              "amountOfCapital",
              "corverageDuration",
              "capitalDeathAllCauses",
              "absoluteDefinitiveDisabilityCapital",
              "doublingDeathBenefit",
              "partialPermanentDisability",
              "accumulationContributions",

              // // "clientNumber",
              "subscriber_sexe",
              "subscriber_lastname",
              "subscriber_firstname",
              "subscriber_email",
              "subscriber_birthday",
              "subscriber_phone",
              "subscriber_commune",
              "subscriber_adress",
              "subscriber_profession",
              // "subscriber_identity",

              "assured_sexe",
              "assured_lastname",
              "assured_firstname",
              "assured_email",
              "assured_birthday",
              "assured_phone",
              "assured_commune",
              "assured_adress",
              "assured_categorySocioProfessional",
              "assured_familySituation",
              "assured_profession",
              // "assured_identity",
              "BeneficiariesDeceased_name",
              "survey",
            ];
            headerTitleTmp = [
              "Avis de souscription",
              "Etat contrat",
              "Référence",

              "Statut paiement ",

              "Intermédiaire",

              "Civilité prospect",
              "Nom prospect",
              "Prénoms prospect",
              "Date de naissance prospect",
              "Téléphone prospect",
              "Email prospect",
              "Date d’effet souhaitée prospect",

              "Montant du capital",
              "Durée de couverture",
              "Capital Décès Toutes Causes",
              "Capital Invalidité Absolue et Définitive",
              "Doublement du capital décès",
              "Invalidité Partielle et Permanente",
              "Cotisation",

              // // "Numéro client",
              "Civilité souscripteur",
              "Nom souscripteur",
              "Prénoms souscripteur",
              "Email souscripteur",
              "Date de naissance souscripteur",
              "Téléphone souscripteur",
              "Commune de naissance souscripteur",
              "Adresse de résidence souscripteur",
              "Profession souscripteur",
              // "Pièce d’identité souscripteur",

              "Civilité assuré",
              "Nom assuré",
              "Prénoms assuré",
              "Email assuré",
              "Date de naissance assuré",
              "Téléphone assuré",
              "Commune de naissance assuré",
              "Adresse de résidence assuré",
              "Catégorie socioprofessionnelle assuré",
              "Situation familiale assuré",
              "Profession assuré",
              // "Pièce d’identité assuré",

              "Clause bénéficiaire",
              "Questionnaire médical",
            ];
            mixin.methods.exportToData(
              tableToPrint,
              headerTitleTmp,
              headerValTmp,
              this.fileName,
              this.cellAutoWidth,
              this.selectedFormat
            );
            break;
          default:
            break;
        }
      }
      this.clearFields();
    },
    displayStatus(status) {
      return this.allContratStatus.find((el) => el.id == status).label;
    },
    onGetSubscriber(data) {
      if (data) {
        return {
          subscriber_sexe: data.civility,
          subscriber_lastname: data.lastname,
          subscriber_firstname: data.firstname,
          subscriber_email: data.email,
          subscriber_birthday: this.formatDate(data.birthday),
          subscriber_phone: data.phone,
          subscriber_commune: data.commune,
          subscriber_adress: data.adress,

          subscriber_categorySocioProfessional: this.allCategorySocioPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocioPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          subscriber_familySituation: this.familySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.familySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          subscriber_profession: data.profession,
          subscriber_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          subscriber_sexe: "",
          subscriber_lastname: "",
          subscriber_firstname: "",
          subscriber_email: "",
          subscriber_birthday: "",
          subscriber_phone: "",
          subscriber_commune: "",
          subscriber_adress: "",
          subscriber_categorySocioProfessional: "",
          subscriber_familySituation: "",
          subscriber_profession: "",
          subscriber_identity: "",
        };
      }
    },
    onGetAssured(data) {
      if (data) {
        return {
          assured_sexe: data.civility,
          assured_lastname: data.lastname,
          assured_firstname: data.firstname,
          assured_email: data.email,
          assured_birthday: this.formatDate(data.birthday),
          assured_phone: data.phone,
          assured_commune: data.commune,
          assured_adress: data.adress,
          assured_categorySocioProfessional: this.allCategorySocioPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocioPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          assured_familySituation: this.familySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.familySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          assured_profession: data.profession,
          // assured_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          assured_sexe: "",
          assured_lastname: "",
          assured_firstname: "",
          assured_email: "",
          assured_birthday: "",
          assured_phone: "",
          assured_commune: "",
          assured_adress: "",
          assured_categorySocioProfessional: "",
          assured_familySituation: "",
          assured_profession: "",
          // assured_identity: "",
        };
      }
    },
    onGetBeneficiariesDeceased(data) {
      if (data) {
        return {
          BeneficiariesDeceased_name:
            data.name == "" && data.value == ""
              ? `${data.customForm.lastname}, ${
                  data.customForm.firstname
                }, ${this.formatDate(data.customForm.birthday)}`
              : data.name == "myPartner"
              ? " Mon conjoint, à défaut mes enfants nés et à naître, à défaut mes ayants droit"
              : `${data.value}`,
        };
      } else {
        return {
          BeneficiariesDeceased_name: "",
        };
      }
    },
    getFilesList(item) {
      this.filesList = []
      if(item.filename) {
        this.filesList.push({name: "Bulletin d'adhésion", filename: item.filename})
      }
      this.filesList.push({name: "Questionnaire médical (" + item.quote.firstname + " " + item.quote.lastname + ")", filename: item.quote.surveyFilename, valideQMStandard: mixin.methods.valideQMStandard(item.quote) })
       
      if(item.subscription.assuredList.length !== 0) {
        item.subscription.assuredList.forEach(assured => {
          this.filesList.push({name: "Questionnaire médical (" + assured.firstname + " " + assured.lastname + ")", filename: assured.surveyFilename, valideQMStandard: mixin.methods.valideQMStandard(assured)})
        })
      }
      
      this.popupListFiles = true;
    },
    onGetTarif(productName, data) {
      console.log(productName, data, "(productName, data) ");
      if (productName == "SOHU") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            accessoir: propositionChoose.accessoir,
            amountOfCapital: parseInt(propositionChoose.amountOfCapital),
            capitalDeathAllCauses: parseInt(
              propositionChoose.capitalDeathAllCauses
            ),
            accumulationContributions: parseInt(
              propositionChoose.accumulationContributions
            ),
            absoluteDefinitiveDisabilityCapital: parseInt(
              propositionChoose.absoluteDefinitiveDisabilityCapital
            ),
            corverageDuration: parseInt(propositionChoose.corverageDuration),
            periodicity: "Unique",
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            accessoir: "",
            amountOfCapital: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
            periodicity: "",
          };
        }
      } else if (productName == "AAF") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            accessoir: propositionChoose.accessoir,
            amountOfCapital: parseInt(propositionChoose.amountOfCapital),
            capitalDeathAllCauses: "",
            accumulationContributions: parseInt(
              propositionChoose.accumulationContributions
            ),
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: parseInt(propositionChoose.corverageDuration),
            periodicity: "Annuelle",
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            accessoir: "",
            amountOfCapital: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
            periodicity: "",
          };
        }
      } else if (productName == "AHR") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            ahr_amountOfCapital: parseInt(propositionChoose.amountOfCapital),
            ahr_contributionPeriod: "Mensuelle",
            ahr_corverageDuration: parseInt(
              propositionChoose.corverageDuration
            ),
            ahr_startDateContributions: this.formatDate(
              data.startDateContributions
            ),
            ahr_endDateContributions: this.formatDate(
              propositionChoose.endDateContributions
            ),
            ahr_numberOfContributions: parseInt(
              propositionChoose.numberOfContributions
            ),
            ahr_accumulationOfContributions: parseInt(
              propositionChoose.accumulationOfContributions
            ),
            ahr_accumulationContributions: parseInt(
              propositionChoose.accumulationContributions
            ),
          };
        } else {
          return {
            ahr_amountOfCapital: "",
            ahr_contributionPeriod: "",
            ahr_corverageDuration: "",
            ahr_startDateContributions: "",
            ahr_endDateContributions: "",
            ahr_numberOfContributions: "",
            ahr_accumulationOfContributions: "",
            ahr_accumulationContributions: "",
          };
        }
      } else if (productName == "AAE") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            aae_amountOfCapital: parseInt(propositionChoose.amountOfCapital),
            aae_deathOrAbsoluteDefinitiveDisability:
              propositionChoose.deathOrAbsoluteDefinitiveDisability
                ? "Oui"
                : "Non",
            aae_periodicityOfPayment: this.allPeriodicityOfPayment.find(
              (el) => el.id == propositionChoose.periodicityOfPayment
            )
              ? this.allPeriodicityOfPayment.find(
                  (el) => el.id == propositionChoose.periodicityOfPayment
                ).label
              : "",

            aae_corverageDuration: propositionChoose.corverageDuration,
            aae_startDateContributions: this.formatDate(
              data.startDateContributions
            ),
            aae_endDateContributions: this.formatDate(
              propositionChoose.endDateContributions
            ),
            aae_numberOfContributions: parseInt(
              propositionChoose.numberOfContributions
            ),
            aae_accumulationOfContributions: parseInt(
              propositionChoose.accumulationOfContributions
            ),
            aae_accumulationContributions: parseInt(
              propositionChoose.accumulationContributions
            ),
          };
        } else {
          return {
            aae_amountOfCapital: "",
            aae_periodicityOfPayment: "",
            aae_deathOrAbsoluteDefinitiveDisability: "",
            aae_corverageDuration: "",
            aae_startDateContributions: "",
            aae_endDateContributions: "",
            aae_numberOfContributions: "",
            aae_accumulationOfContributions: "",
            aae_accumulationContributions: "",
          };
        }
      }
    },
    async fetchApplyPutProductContent(paymentRequest) {
      let x = {
        avenantId: this.byProductContent.id,
        quittanceId: this.byProductContent.id,
        transactionId: paymentRequest.data.transactionId,
        paymentRequest,
      };
      if (
        this.formUpdated.productName == "SOHU"
      ) {
        await this.applyPutAvenant(x);
      }
    },
    onGetPaymentStatus(data) {
      switch (data.productName) {
        case "SOHU":
          return this.allPaymentStatus.find(
            (el) =>
              el.id ==
              data.avenants.find((av) => av.isDefault == true).paymentStatus
          ).label;

        default:
          return this.allPaymentStatus.find(
            (el) =>
              el.id ==
              data.quittances.find((quit) => quit.isDefault == true)
                .paymentStatus
          ).label;
      }
    },
    onGetSurvey(data) {
      if (data) {
        let surveyData = [];
        data.forEach((res) => {
          if (res.response.text) {
            surveyData.push(
              `${res.question}: ${res.response.text.value} ${res.response.text.placeholder} `
            );
          } else {
            surveyData.push(
              `${res.question}: ${res.response.quickResponse ? "OUI" : "NON"}`
            );
          }
        });
        return { survey: surveyData.join("; ") };
      } else {
        return { survey: "" };
      }
    },
    onDisplayMarkPayment(isClose) {
      this.popupMarkPayment = isClose;
    },

    onDisplayRelaunch(isClose) {
      this.popupRelaunch = isClose;
    },

    onDisplayEnabledPolice(isClose) {
      this.popupEnabledPolice = isClose;
    },

    displayNotice(status) {
      return this.noticeList.find((el) => el.id == status).label;
    },
    displayType(type) {
      return this.canalList.find((el) => el.id == type).label;
    },
    checkComment(item) {
      this.souscriptionId = item.id;
      this.isClose = true;
    },
    isClosed(value) {
      this.isClose = value;
      console.log("la fermeture");
    },
    async getIntermediarySys() {
      let data = {
        limit: "Tout",
        skip: 0,
        type: [
          "broker_inspector",
          "broker_leader",
          "broker_merchant",
          "broker_general_agent",
          "broker",
        ],
      };
      this.loading = true;

      try {
        let intermediary = await this.applyGetIntermediariesSys(data);
        intermediary.list.forEach((el) => {
          this.intermediariesList.push({
            id: el.id,
            fullname: el.person.firstname + " " + el.person.lastname,
          });
        });

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    resetFilter() {
      this.isResetFilter = true;
      this.canal = "";
      this.intermediary = "";
      this.subscription = "";
      this.categorySocPro = "";
      this.paymentStatus = "";
      this.categorySocPro = "";
      this.famillySituation = "";
      this.isResetFilter = false;
      this.fetchApplyGetAllContratIssue({
        limit: this.limit,
        skip: this.skip,
        productName: this.crrModuleName,
        status: "none",
        paymentStatus: "",
        notice: this.subscription.id
          ? [this.subscription.id]
          : ["standardValidation", "validationWithExtraPremium"],
        type: this.canal.id ? this.canal.id : "",
        categorySocioProfessional: this.categorySocPro.id
          ? this.categorySocPro.id
          : "",
        familySituation: this.famillySituation.id
          ? this.famillySituation.id
          : "",
        broker: this.intermediary.id ? this.intermediary.id : "",
      });
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    checkViewFile(filename) {
      window.open(this.showFileData(filename), "_blank");
    },
    checkDropFile(filename) {
      window.open(this.showFileData(filename), "_blank");
    },

    showFileData(data) {
      console.log("datatelech", data);
      return `${this.$config.serverURL}/downloads/${data}`;
    },

    async fetchApplyGetAllProjectDepth() {
      await this.applyGetAllProjectDepth({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        productName: this.crrModuleName,
        status: "none",
        paymentStatus:
          this.paymentStatus && this.paymentStatus.id
            ? this.paymentStatus.id
            : "",
        notice:
          this.subscription && this.subscription.id
            ? [this.subscription.id]
            : ["standardValidation", "validationWithExtraPremium"],
        type: this.canal && this.canal.id ? this.canal.id : "",
        categorySocioProfessional:
          this.categorySocPro && this.categorySocPro.id
            ? this.categorySocPro.id
            : "",
        familySituation:
          this.famillySituation && this.famillySituation.id
            ? this.famillySituation.id
            : "",
        broker:
          this.intermediary && this.intermediary.id ? this.intermediary.id : "",
        exportAvenant: true,
        exportQuittance: true,
      });

      this.activePrompt = true;
      // console.log("allProjectsDepth", this.allProjectsDepth);
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },

    async fetchApplyGetAllContratIssue(data) {
      this.loadingForTable = true;

      try {
        await this.applyGetAllProject(data).then((res) => {
          this.total = this.allProjects.total;
          let p = Math.ceil(this.total / this.limit);
          this.allPages = p ? p : 1;
          setTimeout(() => {
            this.loadingForTable = false;
          }, 500);
        });
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadingForTable = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    applyGetDefaultPayment(project) {
      if (project.productName == "SOHU") {
        this.byProductContent = project.getDefaultAvenant;
      }
    },
    checkPayed(item) {
      this.crrID = item.id;
      this.applyGetDefaultPayment(item);
      this.formUpdated = { ...item };
      this.popupPaiement = true;
    },

    checkPayedWithAgregator() {
      this.popupPaiement = false;
      this.formPayed = {
        ...this.formPayed,
        cotisationTTC: this.byProductContent.cotisationTTC,
      };

      if (this.formUpdated.notice === "validationWithExtraPremium") {
        this.popupPaiementUpdateTTC = true;
      } else {
        this.payedWithAgregator();
      }
    },
    async fetchApplycheckPayedWithAgregator() {
      if (!this.validateFormPayed) return false;
      this.loadingFormPayed = true;

      try {
        if (
          this.formUpdated.productName == "SOHU"
        ) {
          await this.applyPutAvenant({
            ...this.byProductContent,
            avenantId: this.byProductContent.id,
            cotisationTTC: this.formPayed.cotisationTTC,
          }).then((res) => {
            this.popupPaiementUpdateTTC = false;
            this.loadingFormPayed = false;
            this.payedWithAgregator();
          });
        }

      } catch (err) {
        this.popupPaiementUpdateTTC = false;
        this.loadingFormPayed = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "payement").error
                  );
                  k;
              }
            });
          }
        }
      }
    },

    payedWithAgregator() {
      this.woIsAction = this.crrID;
      this.loadingUpdateContrat = true;

      let newData = {};
      let newAmount = this.formPayed.cotisationTTC;

      if (
        this.formUpdated.productName == "SOHU"
      ) {
        newData = { avenantId: this.byProductContent.id };
      }
      localStorage.setItem("byTypePayment", "ContractIssue");

      openKkiapayWidget({
        amount: newAmount,
        position: "right",
        callback: "",
        data: {
          ...newData,
          paymentRequest: {
            type: "momoCard",
            data: {
              datePayment: moment(new Date()).format("D MMMM YYYY à HH:mm"),
              userType: this.user.type,
              userId: this.user.id,
              userFullname:
                this.user.person.firstname + " " + this.user.person.lastname,
            },
          },
        },
        theme: "#AEC340",
        sandbox: "false", // changer in PROD
        key: global.APIKEYKKIAPAY, // change in PROD : APIKEYKKIAPAY
      });
    },

    payedWithoutAgregator() {
      this.popupPaiement = false;
      this.popupMarkPayment = true;
    },

    checkCancelPayed(item) {
      this.crrID = item.id;
      this.applyGetDefaultPayment(item);
      this.formUpdated = { ...item };

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment annuler le paiement sur ce contrat ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.cancelPayedIssueContrat,
      });
    },

    async cancelPayedIssueContrat() {
      this.woIsAction = this.crrID;
      this.loadingUpdateContrat = true;

      let data = {
        avenantId: this.byProductContent.id,
        quittanceId: this.byProductContent.id,
        paymentStatus: "required",
        cancelPay: true,
        paymentRequest: this.byProductContent.paymentRequest,
      };
      try {
        if (
          this.formUpdated.productName == "SOHU"
        ) {
          await this.applyChangeAvenantState(data);
        }
        if (
          this.formUpdated.productName == "AHR" ||
          this.formUpdated.productName == "AAE"
        ) {
          await this.applyChangeQuittanceState(data);
        }

        this.acceptAlert(
          mixin.methods.message("subscription", "cancelPayment").success
        );
        this.formUpdated = {};
        this.loadingUpdateContrat = false;
        this.woIsAction = "";
        this.contratSelected = [];
      } catch (err) {
        this.loadingUpdateContrat = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "cancelPayment").error
                  );
                  break;
              }
            });
          }
        }
      }
    },

    enabledPolice(item) {
      this.formUpdated = item;
      this.applyGetDefaultPayment(item);
      this.popupEnabledPolice = true;
    },
    checkChangeIntermediary(item) {
      this.crrID = item.id;
      this.formUpdated = { ...item };

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment changer d intermédiaire ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.changeIntermediary,
      });
    },
    ChangeIntermediary(item) {
      this.formInterm = item;

      if (item.broker) {
        this.intermediariesList.forEach((el) => {
          if (el.id == item.broker.id) {
            this.intermediaryUpdated = el;
          }
        });
      }

      this.popupActiveChangeIntermediary = true;
    },

    async fetchApplyChangeIntermediary() {
      let data = {
        ...this.formInterm,
        projectId: this.formInterm.id,
        quote: this.formInterm.quote ? this.formInterm.quote.id : "",
        subscription: this.formInterm.subscription
          ? this.formInterm.subscription.id
          : "",
        client: this.formInterm.client ? this.formInterm.client.id : "",
        broker: this.intermediaryUpdated ? this.intermediaryUpdated.id : "",
      };
      this.loadingChangeIntermediary = true;

      try {
        await this.applyChangeIntermediary(data);
        this.loadingChangeIntermediary = false;
        this.acceptAlert(
          mixin.methods.message("subscription", "ChangeIntermediary").success
        );
        this.intermediaryUpdated = {};
        this.popupActiveChangeIntermediary = false;
      } catch (err) {
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Administrateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "ChangeIntermediary")
                      .error
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkRemoveMultipleContrat() {
      this.contratSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les contrats en cours sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleContrat,
      });
    },

    async fetchRemoveMultipleContrat() {
      let data = {
        ids: this.crrIDs,
      };

      this.loadingForTable = true;
      try {
        await this.applyRemoveProject(data);
        this.loadingForTable = false;
        this.acceptAlert(
          mixin.methods.message("subscription", "multipleDeletion").success
        );
        this.crrIDs = [];
        this.contratSelected = [];
      } catch (err) {
        this.loadingForTable = false;
        this.crrIDs = [];
        this.contratSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "multipleDeletion")
                      .error
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkRemove(contrat) {
      this.crrID = contrat.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ce contrat ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveContrat,
      });
    },

    async fetchApplyRemoveContrat() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID],
      };
      this.loadingRemoveContrat = true;
      try {
        await this.applyRemoveProject(data);
        this.loadingRemoveContrat = true;
        this.acceptAlert(
          mixin.methods.message("subscription", "delete").success
        );
        this.woIsAction = "";
        this.contratSelected = [];
      } catch (err) {
        this.loadingRemoveContrat = false;
        this.woIsAction = "";
        this.contratSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "projectNotFound":
                  this.errorMsg = "Projet inexistant";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Projet inexistant"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "delete").error
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkRelaunch(item) {
      this.formUpdated = { ...item };
      this.popupRelaunch = true;
    },
  },
  watch: {
    paymentStatus(val) {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },
    subscription() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    endDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    canal() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    categorySocPro() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    famillySituation() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    intermediary() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },

    limit() {
      this.skip = 0;

      if (!this.isResetFilter) {
        this.fetchApplyGetAllContratIssue({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          productName: this.crrModuleName,
          status: "none",
          paymentStatus:
            this.paymentStatus && this.paymentStatus.id
              ? this.paymentStatus.id
              : "",
          notice:
            this.subscription && this.subscription.id
              ? [this.subscription.id]
              : ["standardValidation", "validationWithExtraPremium"],
          type: this.canal && this.canal.id ? this.canal.id : "",
          categorySocioProfessional:
            this.categorySocPro && this.categorySocPro.id
              ? this.categorySocPro.id
              : "",
          familySituation:
            this.famillySituation && this.famillySituation.id
              ? this.famillySituation.id
              : "",
          broker:
            this.intermediary && this.intermediary.id
              ? this.intermediary.id
              : "",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
